<template>
  <div>
    <BaseLoading
      style="position: fixed; z-index: 3; top: 50%; right: 50%"
      v-if="is_loading"
    ></BaseLoading>
    <div class="order-info text-left">
      <div class="row">
        <div class="col-12 col-sm-7 table-responsive">
          <div class="white-block order-block">
                  <div class="t-order__head-wrap">
                  <div class="t-order__top-wrap">
                  <!-- <h3 class="t-page-title uppercase">
                  Order #3347411 </h3> -->
                  <!-- <a href="/customers/order/form/3347411?of_id=1989" class="t-btn t-btn_default" role="button">
                  Edit Order </a> -->
                  </div>
                  <!-- <div class="t-row-start t-order-state">
                  <div class="progress-state right-margin not_paid">
                  <div class="t-order__status-name">Not paid</div>
                  </div>
                  <span class="progress-state-text not_paid fs13 ">
                  Your order has not been paid yet. </span>
                  </div> -->
                  <div class="t-row">
                  <div class="lato-700">
                 Title: Any topic (Writer's choice) </div>
                  </div>
                  </div>
                  <div class="t-order-info__wrap">
                  <div class="t-order-info t-block_border-dot t-block_bg-grey">
                  <div class="t-info-flex">
                  <div class="t-info-flex-w">
                  <div class="t-info">
                  <div class="t-info__key">Creation date:</div>
                  <div class="t-info__date-wrap">
                  <div class="t-info__val">{{order.date_placed}}</div>
                  <!-- <div class="t-info__val t-info__val_l-grey">10:53 AM</div> -->
                  </div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Urgency:</div>
                  <div class="t-info__val">{{order.urgency_text_hrs}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Subject:</div>
                  <div class="t-info__val">{{order.subject_area_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Type of work:</div>
                  <div class="t-info__val">{{order.document_type_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Number of pages:</div>
                  <div class="t-info__val">
                  {{order.pages}} </div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Spacing:</div>
                  <div class="t-info__val">{{order.spacing_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Charts:</div>
                  <div class="t-info__val">{{order.charts}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Slides:</div>
                  <div class="t-info__val">{{order.slides}}</div>
                  </div>
                  </div>
                  <div class="t-info-flex-w">
                  <div class="t-info">
                  <div class="t-info__key">Level:</div>
                  <div class="t-info__val">{{order.academic_level_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Style:</div>
                  <div class="t-info__val">{{order.style_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Sources:</div>
                  <div class="t-info__val">{{order.no_sources}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Language:</div>
                  <div class="t-info__val">{{order.language_style_text}}</div>
                  </div>
                  <!-- <div 
                   
                  class="t-info">
                  <div class="t-info__key">Additional Services:</div>
                  <div 
                  v-for="(item, index) in order.additional_services_text"
                    :key="index + 'add'"
                  class="t-info__val d-flex flex-column ">{{ item[0] }}</div>
                  </div> -->

                  <div class="t-info">
                  <div class="t-info__key">Extras Total:</div>
                  <div class="t-info__val-wrap">
                  <div class="t-info__val t-info__val_separate">
                  <div class="t-vt-separator">
                    ${{ order.extras_total || 0 }}</div>
                  </div>
                  </div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Total Order Cost:</div>
                  <div class="t-info__val">${{order.cost}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Paid:</div>
                  <div class="t-info__val">${{order.total_paid}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Balance:</div>
                  <div class="t-info__val"> ${{ order.balance || 0 }}</div>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div class="t-row py-3">
                  <div class=" t-deadline__wrap">
                  <div class="t-deadline t-deadline_with-icon">
                  <div class="d-flex-center">
                  <div class="t-deadline__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" fill="none" viewBox="0 0 14 15"><path fill="#ABB1BA" d="M3.099 6.745H7v3.759H3.099v-3.76zm9.333 6.744V5.27H1.568v8.22h10.864zm0-11.978c.413 0 .778.152 1.094.456.316.305.474.656.474 1.054V13.49c0 .398-.158.749-.474 1.053a1.53 1.53 0 0 1-1.094.457H1.568c-.438 0-.815-.14-1.13-.421A1.52 1.52 0 0 1 0 13.489V3.021c0-.398.146-.75.438-1.054a1.572 1.572 0 0 1 1.13-.456h.765V0h1.568v1.51h6.198V0h1.568v1.51h.765z"></path></svg>
                  </div>
                  <div class="t-deadline__label">
                  Deadline:
                  </div>
                  <div class="t-deadline__time">
                  <div id="date-real-deadline_date" class="t-info__val">{{order.deadline && order.deadline.ellapsed }}</div>
                  <!-- <div id="date-real-deadline_time" class="t-info__val t-info__val_l-grey">10:53 AM</div> -->
                  </div>
                  </div>
                  </div>
                  <div class="t-deadline__extend-link">
                  </div>
                  </div>
                  <div class="t-col-2">
                  </div>
                  </div>
                  <div class="t-order__bottom-wrap t-row t-block_bg-grey t-block_border-dot">
                  <div class="t-order__not-paid d-flex flex-sm-row flex-column  align-items-sm-center align-items-start justify-content-between ">
                  <div class="d-flex  t-order__paid-wrap">
                    <span class="t-order__paid-label">
                    Placed:
                    </span>
                    <span class="t-order__paid-val">
                    <span class="green">${{order.cost}} </span> 
                    </span>

                  </div>
                  <div class="t-payment-list__order-wrap">
                  <div class="t-payment-list__wrap">
                  <div class="t-payment-list js-loader_hide">
                  <div class="selectr-container selectr-desktop has-selected" style="width: 100%;">

                  
                  </div> </div>
                  
                  <div class="t-payment-list__btn-wrap">
                  <router-link :to="{ name: 'payment', params: { id: order.id } }" id="checkoutBtn" class="t-payment-list__btn ms-700">Secure checkout</router-link>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div class="t-block t-block_white t-instructions_noconfirm">
                  <div class="t-flex align-items-center justify-content-start">
                  <h3 class="t-page-title t-page-title_uppercase">
                  Instructions </h3>
                  </div>
                  <div id="instructions" class="t-instructions__list">
                  <div class="list-item">
                  <div class="t-instructions__list-item">
                  <div class="t-instructions__list-content">
                    <!-- {{order}} -->
                    <span v-html="order.description" ></span>

                  </div>
                  <div :class="order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success' "  class="t-instructions__list-content_light">
                  Placed: {{order.deadline && order.deadline.ellapsed }}</div>
                  </div>
                  </div>
                  <div class="bg-linear hide"></div>
                  </div>
                  </div>

                  <div class="t-block t-block_white t-instructions_noconfirm">
                  <div class="t-flex align-items-center justify-content-start">
                  <h3 v-if="order.extras && order.extras.length > 0" class="t-page-title t-page-title_uppercase">
                  Additional Services </h3>
                  </div>
                  <div v-if="order.extras && order.extras.length > 0" id="instructions" class="t-instructions__list">
                  <div class="list-item">
                  <div class="t-instructions__list-item">
                  <div class="t-instructions__list-content">
                    <!-- {{order}} -->
                    <div
                    v-for="(extra, index) in order.extras"
                    :key="index + 'oextras'"
                    class="d-flex justify-content-between py-2 align-items-center"
                    
                  >
                    <span>{{ extra.name || "" }}</span>
                    <span>: ${{ extra.cost || 0 }}</span>
                    <div>
                      <button
                        v-if="extra.paid !== '0'"
                        class="ml-1 btn btn-sm bg-teal"
                      >
                        Paid
                      </button>
                      <span
                        v-else
                        class="d-flex justify-content-center align-items-center"
                      >
                        Unpaid
                        <button
                          @click="deleteExtra(extra.id)"
                          class="mx-2 btn btn-sm  btn-outline-danger"
                        >
                          Delete
                        </button>
                      </span>
                    </div>
                  </div>

                  </div>
                  
                  </div>
                  </div>
                  <div class="bg-linear hide"></div>
                  </div>
                  <div v-else id="instructions" class="t-instructions__list">
                  <div class="list-item">
                  <div class="t-instructions__list-item">
                  <div class="t-instructions__list-content">
                    <!-- {{order}} -->
                  <div class="d-flex flex-column justify-content-center py-2 align-items-center">
                    <div class="custom_imageEmpty"></div>
                    <span  >No  Additional Services! </span>

                  </div>

                  </div>
                  
                  </div>
                  </div>
                  <div class="bg-linear hide"></div>
                  </div>
                  </div>
          <!-- <table
            class="table table-stripped text-left bg-light"
            style="font-size: 13px !important"
          >
            <tr>
              <td>Topic</td>
              <td>: {{ order.title || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Type of Paper</td>
              <td>: {{ order.document_type_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Paper Details</td>
              <td colspan="2" style="padding: 4px !important">
                <pre style="padding: 4px !important"> 
                  <span v-html="order.description"></span>
                </pre>
              </td>
            </tr>
            <tr>
              <td>Paper Format / Style</td>
              <td>: {{ order.style_text || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Date Ordered</td>
              <td>: {{ order.date_placed || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Order Deadline</td>
              <td
                :class="
                  order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                : {{ order.deadline && order.deadline.date || "" }}
              </td>
              <td
                :class="
                  order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success'
                "
                class="font-weight-bold"
              >
                {{ order.deadline && order.deadline.past_due ? "Elapsed" : "" }}
                <span class="font-weight-black">{{ order.deadline && order.deadline.ellapsed || "" }}</span>
              </td>
            </tr>
            <tr>
              <td>Urgency</td>
              <td>
                <span
                  v-if="order.deadline"
                  :class="order.deadline.past_due ? 'text-danger' : 'text-success'"
                  >: {{ order.urgency_text_hrs || "" }}</span
                >
              </td>
              <td></td>
            </tr>

            <tr>
              <td>Number of sources</td>
              <td>: {{ order.no_sources || "0" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Writer Level</td>
              <td>: {{ order.writer_level_desc || "0" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Additional Services</td>
              <td>
                <table class="table table-borderless">
                  <tr
                    v-for="(item, index) in order.additional_services_text"
                    :key="index + 'add'"
                  >
                    <td>{{ item[0] }}</td>
                    <hr />
                  </tr>
                </table>
              </td>
              <td></td>
            </tr>

            <tr>
              <td>Spacing</td>
              <td>: {{ order.spacing_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Number of Page(s)</td>
              <td>
                : {{ order.pages || "" }} 
                 / Aprox {{ no_of_words || "275" }} words
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Number of Slide(s)</td>
              <td>: {{ order.slides || "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Number of Chart(s)</td>
              <td>: {{ order.charts || "" }}</td>
              <td></td>
            </tr>

            <tr
              v-for="(extra, index) in order.extras"
              :key="index + 'oextras'"
              :class="extra.paid !== '0' ? 'alert-success' : 'alert-danger'"
            >
              <td>{{ extra.name || "" }}</td>
              <td>: ${{ extra.cost || 0 }}</td>
              <td>
                <button
                  v-if="extra.paid !== '0'"
                  class="ml-1 btn btn-sm bg-teal"
                >
                  Paid
                </button>
                <span
                  v-else
                  class="d-flex justify-content-center align-items-center"
                >
                  Unpaid
                  <button
                    @click="deleteExtra(extra.id)"
                    class="mx-1 btn btn-sm btn-outline-danger"
                  >
                    Delete
                  </button>
                </span>
              </td>
            </tr>
            <tr class="alert alert-primary border-0">
              <td class="font-weight-bold">Extras Total</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.extras_total || 0 }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="font-weight-bold">Total Order Cost</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.cost }}</td>
              <td></td>
            </tr>
            <tr class="alert alert-success border-0">
              <td class="font-weight-bold">Paid</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.total_paid || 0 }}</td>
              <td></td>
            </tr>
            <tr class="alert alert-danger border-0">
              <td class="font-weight-bold">Balance</td>
              <td class="font-weight-bold" style="font-size: 18px;">: ${{ order.balance || 0 }}</td>
              <td></td>
            </tr>
          </table> -->
        </div>

        <div class="col-12 col-sm-5">
          <h4 class="font-weight-bold mt-2">Add Services to Your Paper</h4>
          <div class="alert alert-info">
            <strong>Note: </strong> Additional services will apply once you pay
            for them!
          </div>
          <div class="row">
            <div
              v-for="(item, index) in additionalItems"
              :key="index"
              class="col-6 py-3"
            >
              <p
                class="font-weight-bold text-center is-additional"
                @click="activeComponent = item"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <img width="80px" :src="item.icon || ''" />
                <br />
                <strong>{{ item.title || "" }}</strong>
              </p>
            </div>

            <!-- <div class="col-12">
              <div v-if="order.is_paid">
                <div class="media alert alert-success">
                  <div class="mr-3">
                    <a
                      href="#"
                      class="btn bg-transparent border-success text-success rounded-round border-2 btn-icon"
                    >
                      <i class="icon-checkmark3"></i>
                    </a>
                  </div>

                  <div class="media-body">
                    Your order is paid!
                    <div class="text-muted">We've received your order payment!</div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- Modal -->
      <BaseModal :title="activeComponent.title">
        <component :is="activeComponent.component"></component>
      </BaseModal>
    </div>
  </div>
</template>


<script>
import pages_png from "../../assets/global_assets/images/ordericons/pages.png";
import slides_png from "../../assets/global_assets/images/ordericons/slides.png";
import charts_png from "../../assets/global_assets/images/ordericons/charts.png";
import deadline_png from "../../assets/global_assets/images/ordericons/deadline.png";
import progressive_png from "../../assets/global_assets/images/ordericons/progressive.png";
import preferences_png from "../../assets/global_assets/images/ordericons/preferences.png";

import AdditionalPages from "../order/modals/AdditionalPages.vue";
import AdditionalServices from "../order/modals/AdditionalServices.vue";
import AdditionalCharts from "../order/modals/AdditionalCharts.vue";
import AdditionalSlides from "../order/modals/AdditionalSlides.vue";
import ShortenDeadline from "../order/modals/ShortenDeadline.vue";
import WriterPreferences from "../order/modals/WriterPreferences.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  components: {
    AdditionalPages,
    AdditionalServices,
    AdditionalCharts,
    AdditionalSlides,
    ShortenDeadline,
    WriterPreferences,
  },
  computed: {
    ...mapGetters("setups", ["number_of_pages", "spacing"]),
    // no_of_words() {
    //   return (
    //     275 *
    //       this.order.no_pages *
    //       this.spacing.find((x) => x.id == this.order.spacing).factor || 1
    //   );
    // },
  },
  data() {
    return {
      is_loading: false,
      activeComponent: {},
      additionalItems: [
        {
          title: "Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        },
        {
          title: "Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        },
        {
          title: "Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        },
        {
          title: "Shorten Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        },
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        },
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],
    };
  },

  methods: {
    ...mapActions("order", ["deleteExtras"]),
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  padding:  8px !important;
  max-width: 300px;
}
.is-additional {
  cursor: pointer;
  img {
    margin: 4px;
  }
  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.3s all ease-in-out;
    }
  }
}
</style>
