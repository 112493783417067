<template>
  <div class="text-left">
    <!-- <h4 class="my-2">Order ID: #8989</h4> -->
    <div class="px-2">
      <div class="row" v-if="order.writer">
        <div class="col-12">
          <!-- <h1>Rating</h1> -->
        </div>
        <!-- writer summary -->
        <div class="col-md-5">
          <!-- Writer summary -->

          <div>
            <div class="card card-body text-center border-0 shadow rounded">
              <a href="#" class="d-inline-block mb-3">
                <img
                  src="../../assets/global_assets/images/image.png"
                  class="rounded-round"
                  alt
                  width="100"
                  height="100"
                />
              </a>
              <div class="mb-3">
                <h3
                 style="font-weight:700;"
                  class="font-weight-semibold mb-0 mt-1"
                >{{ order.writer.writer_name || "" }} ( {{ order.writer_id || "" }} )</h3>
                <h1 class="d-block text-dark">
                  <strong>{{ order.writer.completed_orders || 0 }}</strong> Completed Orders
                </h1>
              </div>

              <div class="row">
                <div class="col-5 is-rating-info">
                  <!-- Total rating -->
                  <h1
                    class="display-4 mb-0 font-weight-bold d-flex"
                  >{{ order.writer.rating.rate || '0' }}</h1>
                  <!-- Rating stars -->
                  <p class="is-rating-stars-1 d-flex mb-1">
                    <span v-for="(i, index) in 5" :key="index + 'rating'">
                      <span
                        class="text-warning icon"
                        :class="order.writer.rating.rate >= index + 1 ? 'icon-star-full2' : 'icon-star-empty3'"
                      ></span>
                    </span>
                  </p>
                  <!-- No of reviews -->
                  <h4
                    class="text-weight-bold d-flex"
                  >{{ order.writer.rating.total_reviews || 0 }} Reviews</h4>
                </div>

                <div class="col-7">
                  <!-- Rating Bars -->
                  <div>
                    <div
                      class="my-1  text-start"
                      v-for="(count, index) in order.writer.rating.rating_count"
                      :key="index + 'rating'"
                    >
                      <span class="float-left mr-1">{{count.name || ''}}</span>
                      <span class="progress rounded-round mt-2">
                        <div
                          class="progress-bar bg-success"
                          :style="`width: ${ratepercentage(count.count)}%`"
                        >
                          <span>{{count.count || 0}}</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- rate writer -->
        <div class="col-md-7" >
          <!-- can be included in a popup / modal -->
          <div class="card shadow border-0">
            <div class="card-body">
              <h4>My Review: {{ order.writer.rating.message || "" }} </h4>
            </div>
          </div>
          <RateAction v-if="order.status_text == 'approved'"></RateAction>

          <!-- Reviews listing -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RateAction from "./RateAction.vue";
export default {
  name: "OrderMessages",
  components: {
    RateAction,
  },
  computed: {
    ...mapState("order", ["order"]),
  },

  methods: {
    ratepercentage(count) {
      if (this.order.writer.rating.rate && count > 0) {
        return (count / this.order.writer.rating.rate) * 100;
      }
      return 0;
    },
  },
};
</script>


<style lang="scss">
.is-rating-stars-1 {
  span.icon {
    font-size: 24px;
  }
}
.is-rating-info {
  is-rating-stars-1 {
    min-width: 120px !important;
    max-width: 120px !important;
  }
}
</style>