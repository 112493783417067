<template>
    <div>
        <p>
            Choose the number of additional slides needed to complete your order. 
        </p>

        <div class="form-group row">
        <label class="col-form-label col-sm-12 text-left">Additional Slides</label>
        <div class="col-sm-12">
            <div class="input-group">
            <span class="input-group-prepend">	
                <button class="btn btn-dark btn-icon px-3" type="button" @click="updateSlides(0)"><i class="icon-minus3"></i></button>
            </span>
            <input @change="_calculatePrice(orderForm)" type="number"  style="max-width: 350px!important; " v-model="orderForm.slides" name="slides" min="1" class="form-control text-center" placeholder="">
            <span class="input-group-append">
                <button class="btn btn-dark btn-icon px-3" type="button" @click="updateSlides(1)"><i class="icon-plus3"></i></button>
            </span>
            </div>
            <button class="btn btn-primary mt-2" @click="doUpdateOrder">Add Slides</button>
        </div>
        </div>
    </div>
</template>


<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
    name: "AdditionalSlides",
    computed: {
        ...mapState("order", ["orderForm", "order"])
    },
    methods: {
        ...mapMutations("order", ["updateSlides", "_calculatePrice", "_getOrder"]),
        ...mapActions("order", ["updateOrder"]),
        doUpdateOrder() {
            this.updateOrder(this.orderForm)
                .then((res) => {
                    // success
                    this.$notify({
                        title: "Success",
                        text: res.data.Message || "Additional slides added successifully",
                        style: "success",
                    });
                    // close modal
                    console.log(res);
                })
                .catch((err) => { 
                    console.log(err)
                    this.$notify({
                        title: "Error",
                        text: err.response.data ? err.response.data.Message : "Error",
                        style: "danger",
                    });
                })

        }
    }
}
</script>